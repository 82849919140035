* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  color: #f5f5f5;
  background-color: #060606;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
}
/*# sourceMappingURL=index.770a0063.css.map */
