* {
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  background-color: #060606;
  color: #f5f5f5;
}
